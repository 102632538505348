import type { PropertiesItem, PropertiesDefintion } from './DataObject.PropertiesData.ts';
import type { DataColumn } from 'o365-datagrid';

import { DataGridControl } from 'o365-datagrid';
import PropertiesEditor from './components.PropertiesEditor.vue';

import { h, defineComponent, markRaw } from 'vue';
import { utils } from 'o365-utils';
import { useAsyncComponent } from 'o365-vue-utils';

declare module 'o365-datagrid' {
    interface DataGridControl {
        propertiesData: DataGridPropertiesData;
        hasPropertiesData: boolean;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'propertiesData': {
        get() {
            if (this._propertiesData == null) {
                this._propertiesData = new DataGridPropertiesData(this);
            }
            return this._propertiesData;
        }
    },
    'hasPropertiesData': {
        get() {
            return !!this._propertiesData;
        }
    }
});


export default class DataGridPropertiesData {
    private _dataGridControl: DataGridControl;

    private _initialized = false;

    private _cancelOnPropertyAdded?: () => void;
    private _cancelOnPropertyRemoved?: () => void;
    private _cancelOnPropertyRemovedAsync?: () => void;
    private _cancelUnmounted?: () => void;

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
        this._cancelOnPropertyAdded = this._dataGridControl.dataObject?.propertiesData.events.on('PropertyAdded', (pProperty, pDefinition) => {
            this.onSelected(pProperty, pDefinition)
        })
        this._cancelOnPropertyRemoved = this._dataGridControl.dataObject?.propertiesData.events.on('PropertyRemoved', (pProperty) => {
            this.onUnSelected(pProperty);
        })
        this._cancelOnPropertyRemovedAsync = this._dataGridControl.dataObject?.propertiesData.events.onAsync('PropertyRemovedAsync', async (pProperty, pOptions) => {
            await this.cleanLayoutValues(pProperty, pOptions);
        })
        this._dataGridControl.dataObject?.propertiesData.selectedProperties.forEach(pProperty => {
            this.onSelected(pProperty, this._dataGridControl.dataObject!.propertiesData.propertiesDefinitions[pProperty]);
        });
        this._cancelUnmounted = this._dataGridControl.on('Unmounted', () => {
            this.destroy();
        });
        // this._dataGridControl.dataObject;
    }

    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;

        if (this._dataGridControl.updateVirtualScrollData) {
            this._dataGridControl.updateVirtualScrollData(true);
        }
    }

    onSelected(pProperty: string, pDefinition: PropertiesDefintion) {
        const colId = `Property.${pProperty}`;
        if (this._dataGridControl.dataColumns.columns.find(x => x.colId === colId)) { return; }
        if (this._dataGridControl.dataObject == null) { return; }
        const useDistinct = this._dataGridControl.dataObject?.fields[colId]?.type === 'string' && !(this._dataGridControl.dataObject.recordSource.definitionProc && this._dataGridControl.dataObject.propertiesData.propertyDefintionProc == null);
        this._dataGridControl.addColumn({
            colId: colId,
            headerName: pDefinition.caption ?? pProperty,
            format:pDefinition.format,
            disableDistinct: !useDistinct,
            headerTitle: pDefinition.title ?? pDefinition.caption,
            filter: 'OFilter' as any,
            filterDropdown: useDistinct ? useAsyncComponent('./Filter.Distinct.vue', { importFn: () => import('./Filter.Distinct.vue') }) : null,
            type: this._dataGridControl.dataObject?.fields[colId]?.type,
            // classFn: (row: PropertiesItem) => row.isPropertiesLoading ? 'o365-loading-cell' : undefined,
            getCopyValue: (row: PropertiesItem) => row.isPropertiesLoading ? undefined : utils.formatForCopyValue(row.properties?.[pProperty], this._dataGridControl.dataObject?.fields[colId]?.type),
            // @ts-ignore
            editable: (row: PropertiesItem) => !(row.isNewRecord || row.isBatchRecord) && !row.isPropertiesLoading && pDefinition.dataType !== 'bool' && this._dataGridControl.dataObject?.allowUpdate && (this._dataGridControl.props.systemPropertyIsEditable == null || this._dataGridControl.props.systemPropertyIsEditable(pProperty, pDefinition, row)),
            cellRenderSlot: PropertiesRendererFactory(pProperty, pDefinition),
            cellEditorSlot: PropertiesEditorFactory(pProperty, pDefinition),
            bulkUpdateEditorSlot: PropertiesBulkEditorFactory(pProperty, pDefinition),
            bulkUpdate: this._dataGridControl.dataObject.allowInsert || this._dataGridControl.dataObject.allowUpdate,
        });
    }
    onUnSelected(pProperty: string) {
        const colId = `Property.${pProperty}`;
        if (!this._dataGridControl.dataColumns.columns.find(x => x.colId === colId)) { return; }
        this._dataGridControl.removeColumn(colId);
    }

    async cleanLayoutValues(pProperty: string, pOptions: { modules: string[] }) {
        const colId = `Property.${pProperty}`;
        const column = this._dataGridControl.dataColumns.getColumn(colId);
        if (column && column.hide) {
            pOptions.modules.push('columns');
            column.hide = false;
            if (this._dataGridControl.dataObject?.layoutManager?.activeLayout?.modules.columns?.trackChanges) {
                this._dataGridControl.dataObject?.layoutManager?.activeLayout?.modules.columns?.trackChanges();
            }
        }
        return Promise.resolve();
    }

    destroy() {
        if (this._cancelOnPropertyAdded) {
            this._cancelOnPropertyAdded();
            this._cancelOnPropertyAdded = undefined;
        }
        if (this._cancelOnPropertyRemoved) {
            this._cancelOnPropertyRemoved();
            this._cancelOnPropertyRemoved = undefined;
        }
        if (this._cancelOnPropertyRemovedAsync) {
            this._cancelOnPropertyRemovedAsync();
            this._cancelOnPropertyRemovedAsync = undefined;
        }
        if (this._cancelUnmounted) {
            this._cancelUnmounted();
            this._cancelUnmounted = undefined;
        }
        delete (this._dataGridControl as any)._propertiesData
    }

}

function PropertiesRendererFactory(pProperty: string, pDefinition: PropertiesDefintion) {
    const property = pProperty;
    if (pDefinition.isUrl) {
        return (props: {
            row: PropertiesItem,
            column: DataColumn
        }) => props.row.isPropertiesLoading
                ? ''
                : h('a', { href: props.row.properties?.[property] }, props.row.properties?.[property])
    } else if (pDefinition.dataType === 'bool') {
        const updateBitValue = (pRow: PropertiesItem, pValue: boolean) => {
            if (pRow.properties == null) { return; }
            if (pValue) {
                pRow.properties[property] = 1;
            } else {
                pRow.properties[property] = 0;
                // pRow.propertiesRows[property].IntValue = pRow.propertiesRows[property].oldValues.IntValue;
            }
        };
        return (props: {
            row: PropertiesItem;
            column: DataColumn;
            disabled: boolean;
        }) => props.row.isPropertiesLoading
                ? ''
                : h('input', {
                    type: 'checkbox',
                    checked: props.row.properties?.[property],
                    onChange: (event: Event) => {
                        updateBitValue(props.row, (event.target as HTMLInputElement).checked);
                    },
                    disabled: props.disabled,
                });
    }else if (pDefinition.inputEditor && pDefinition.inputEditor.Multiselect) {
          return (props: {
            row: PropertiesItem;
            column: DataColumn;
        }) => props.row.isPropertiesLoading
                ? ''
                : parseMultiSelectPropValue(props,property)
    } else {
      
        return (props: {
            row: PropertiesItem;
            column: DataColumn;
        }) => props.row.isPropertiesLoading
                ? ''
                : utils.format(props.row.properties?.[property], props.column);
    }
}


function parseMultiSelectPropValue(props:any,property:any){
    if(!props.row.properties?.[property]) null;

    try{
        return JSON.parse(props.row.properties?.[property]).join(", ");
    }catch{
        return utils.format(props.row.properties?.[property], props.column);
    }

}

function PropertiesEditorFactory(pProperty: string, pDefinition: PropertiesDefintion) {
    const property = pProperty;
    return (props: {
        row: PropertiesItem;
        column: DataColumn;
    }) => h(PropertiesEditor, {
        'row': props.row.propertiesRows[property],
        'modelValue': props.row.properties[property],
        'onUpdate:modelValue': newValue => props.row.properties[property] = newValue,
        'config': pDefinition
    });
}

function PropertiesBulkEditorFactory(pProperty: string, pDefinition: PropertiesDefintion) {
    const property = pProperty;

    return markRaw(defineComponent({
        name: `${pProperty}BulkEditor`,
        props: {
            row: null,
            column: null
        },
        setup(props: {
            row: Record<string, any>;
            column: DataColumn;
        }) {
            return () => {
                return h(PropertiesEditor, {
                    'row': props.row,
                    'modelValue': props.row[pDefinition.valueField],
                    'onUpdate:modelValue': newValue => props.row[pDefinition.valueField] = newValue,
                    'config': pDefinition
                })
            };
        },
        inheritAttrs: false,
    }));

    return (props: {
        row: Record<string, any>;
        column: DataColumn;
    }) => h(PropertiesEditor, {
        'row': props.row[property],
        'modelValue': props.row[property],
        'onUpdate:modelValue': newValue => console.log('stop'), //props.row[property] = newValue,
        'config': pDefinition
    });
}